h2 {
  font-size: 34px !important;
  font-weight: normal;
  margin-bottom: 1.25rem !important;
}

.lead {
  font-size: 22px !important;
  max-width: 500px;
}
