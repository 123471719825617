@font-face {
  font-family: SenticoSansDT;
  src: url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/cd/webfonts/senticosansdt-regular-webfont.woff2)
      format("woff2"),
    url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/cd/webfonts/senticosansdt-regular-webfont.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SenticoSansDT;
  src: url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/cd/webfonts/senticosansdt-bold-webfont.woff2)
      format("woff2"),
    url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/cd/webfonts/senticosansdt-bold-webfont.woff)
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  -webkit-text-size-adjust: none;
}

.columnX {
  height: 100%;
}

.button-back {
  transform: rotate(180deg);
  margin-left: -20px !important;
  margin-right: 0px !important;
}
.button-back:hover {
  transform: rotate(180deg) scale(1.033) !important;
}

#signoff .tabFilters {
  padding-right: 100px !important;
  display: none !important;
}

.Markel-LP {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #fafafa;
  font-size: 19px !important;
  font-family: "SenticoSansDT", sans-serif !important;
  font-weight: normal !important;
  font-weight: 1.3 !important;
  color: #4a4a4a;
  overflow-x: hidden;
}

.Markel-LP > * {
  max-width: 1440px !important;
  margin: 0pt auto !important;
  position: relative !important;
}

body {
  background: #fafafa;
  font-size: 18px !important;
  font-family: "SenticoSansDT", sans-serif !important;
  font-weight: normal !important;
  font-weight: 1.3 !important;
}

.container {
  margin: 0 auto;
  max-width: 120rem;
  padding: 0 3rem;
  position: relative;
  width: 100%;
  line-height: 1.4;
}

.column-image {
  align-items: flex-start;
}

.row-center {
  align-items: center !important;
}

/* Markel Landing Page styles */
.LP_section .column {
  min-width: 50%;
  max-width: 50%;
  width: 50%;
}

/* Markel Landing Page styles */
.LP_grid .column {
  min-width: 33%;
  max-width: 33%;
  width: 33%;
}

.one-panel .container {
  z-index: 1;
}

.one-panel {
  overflow: hidden;
}

.one-panel .lead {
  max-width: 4000px;
}

.LP_section,
footer {
  text-align: left;
}

footer {
  background: #333;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
}

.LP_section,
footer {
  padding-top: 5rem !important;
  padding-bottom: 5rem;
}

.section_header .column {
  border-bottom: 1px solid #ddd;
  margin-bottom: 4rem;
}

.section_header .lead {
  margin-bottom: 4rem;
}

.leadspec {
  max-width: none !important;
  margin-bottom: 35px !important;
  margin-top: -13px !important;
}

.column.column-image {
  display: flex;
  justify-content: center;
}
/* Mobile */

.column-diag {
  padding: 50px !important;
  background-color: #ffffff;
  padding-right: 1rem !important;
}

.row-diag {
  //align-items: flex-start !important;
}

.row-diag .column-text {
  padding: 50px;
  padding-left: 1rem;
}

.row-diag {
  background-color: #ffffff;
}
.map-panel {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
  position: relative;
}

@media only screen and (max-width: 801px) {
  #add-form-here {
    max-width: none !important;
  }

  .bigarrow {
    display: none !important;
  }
  .map-panel {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    position: relative;
  }

  .column-diag {
    padding: 50px !important;
  }

  .row-diag {
    background-color: transparent;
  }
  .row-diag .column-text {
    padding-left: 0px;
  }
  .LP_section .column {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
}

.section_header .column {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.rounded,
.rounded2 {
  border-radius: 5px !important;
}

.shaddow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.LP_holding {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  background-size: cover;
  background-position: center center;
  max-width: 10000px;
}

.LP_holding > div {
  max-width: 500px;
  width: 100%;
  text-align: center;
  font-size: 28px;
  //display: flex;
  line-height: 1.2;
  text-align: center;
  //align-items: center;
  //flex-direction: column;
  z-index: 99999;
}

.LP_holding > div > span {
  font-size: 22px;
  display: block;
  margin-top: 25px;
}

.LP_holding > div > b {
  display: inline !important;
}

.Joint_logo {
  max-width: 350px;
  width: 90%;
  display: inline-block;
  margin-bottom: 25px;
}

.LP_holding:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5) !important;
}

.section_header.no-border .column:after {
  display: none;
}

.section_header.no-border .column {
  border-bottom: 0px solid #e0e0e0;
  margin-bottom: 0rem !important;
  padding-top: 0px !important;
}

.column-text ul {
  list-style: solid !important;
}

.column-text ul li {
  margin-left: 20px;
  padding-left: 10px;
}

.LP_holding {
  max-width: 10000px !important;
}

.the-form {
  color: white !important;
  line-height: 1.4 !important;
}

.the-form h2 {
  color: white !important;
}

.the-form {
  padding: 20px !important;
}

.the-results {
  line-height: 1.4 !important;
}

.the-results h2 {
}

.the-results {
  padding: 20px !important;
}

.markel-card {
  background-color: white;
}

.LP_hero {
  flex-direction: column !important;
}

.header-search {
  display: flex;
  margin: 0px;
}

.header-search label {
  display: none;
}

.header-search fieldset {
  display: flex;
  margin: 0px;
}

.header-search input {
  height: 50px;
  border-radius: 0px;
  margin: 0px;
}

.bg-alternate:nth-child(even) {
  background: #ffffff;
}
.bg-alternate:nth-child(odd) {
  background: #f5f5f5;
}

.sticky-titles:nth-child(even) {
  background: #ffffff !important;
}
.sticky-titles:nth-child(odd) {
  background: #f5f5f5 !important;
}

.LP_grid:nth-child(even) > .container:after {
  content: "";
  position: absolute;
  width: 400px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  left: calc(100% - 20px);
  top: 0px;
}

.LP_grid:nth-child(even) > .container:before {
  content: "";
  position: absolute;
  width: 400px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  right: calc(100% - 20px);
  top: 0px;
  z-index: 1 !important;
}

.LP_grid:nth-child(odd) > .container:after {
  content: "";
  position: absolute;
  width: 400px;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.8);
  left: calc(100% - 20px);
  top: 0px;
}

#signoff {
  background-color: #f5f5f5 !important;
}

.LP_grid:nth-child(odd) > .container:before {
  content: "";
  position: absolute;
  width: 400px;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.8);
  right: calc(100% - 20px);
  top: 0px;
  z-index: 1 !important;
}

.bg-alternate {
  overflow: hidden;
}

.button-search {
  padding-left: 35px !important;
  padding-right: 0px !important;
  background-position: center center !important;
  z-index: 2;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}

.header-search .button-search {
  padding-left: 55px !important;
}

.button-carou {
  height: 50px;
  margin-right: -20px;
}
.searcher {
  max-height: 95vh !important;
  overflow: scroll;
}

.imageabs {
  padding-top: 67%;
}

.absimage {
  position: absolute;
  left: 0px;
  top: 0px;
}

.searchcat {
  padding-bottom: 10px;
  color: #2c3038;
  //display: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea,
select {
  padding: 0.7rem 1rem 0.5rem !important;
}

.gwZiig {
  overflow: visible !important;
}

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
input:not([type]):focus,
textarea:focus,
select:focus {
  border-color: #ddd !important;
}

.LP_search_modal .LP_section {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.partner_logo {
  max-width: 210px;
  margin-left: 30px;
  display: none !important;
}

.header-search .button-search {
  background-image: url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/sp/iconmonstr-magnifier-2.svg) !important;
  background-size: 20px !important;
}

@media only screen and (max-width: 801px) {
  .column-map {
    display: none !important;
  }
  .LP_nav > .container > .row {
    flex-direction: column !important ;
  }

  .LP_nav > .container {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .partner_logo {
    margin-left: 20px !important;
  }
  .LP_nav {
    height: 160px !important;
  }

  .logoscol {
    //justify-content: space-between !important;
  }
  .header-search {
    width: 100% !important;
    margin-top: 10px;
  }

  .header-search fieldset {
    width: 100% !important;
  }
}

.tabFilters {
  display: flex;
  margin-bottom: 0px;
  flex-wrap: wrap;
  pointer-events: none !important;
}

.tabFilter {
  
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5 !important;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 18px;
}

.tabFilter:hover {
  background-color: white;
  color: #2c3038 !important;
}

.tabFilter.is-active {
  background-color: white;
  color: rgba(183, 18, 52, 1) !important;
  text-decoration: underline;
}

.searcher h2 {
  margin-bottom: 10px !important;
}

.bg-red a {
  color: white !important;
  text-decoration: underline;
}

.logoscol {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
}

@media only screen and (max-width: 901px) {
  .LP_nav.nav-closed .header-search {
    display: none !important;
  }

  .partner_logo {
    display: none !important;
  }
  .LP_nav > .container > .row {
    flex-direction: row !important;
  }

  .LP_nav.nav-closed .column.searchcol > div {
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 801px) {
  .LP_nav.nav-closed {
    height: 90px !important;
  }
  .logoscol {
    width: 75px !important;
    max-width: 75px !important;
    min-width: 75px !important;
  }

  #nameField {
    background: #ffffff;
  }

  .LP_nav.nav-closed .column.searchcol .header-search {
    margin-top: 0px !important;
  }

  .LP_nav.nav-closed .logoscol {
    //justify-content: space-between !important;
  }

  #markelFooter {
    //margin-bottom: 90px !important;
  }

  .partner_logo {
    max-width: 200px;
  }

  .LP_search_modal__inner {
    padding: 0px !important;
  }

  .searcher {
    max-width: 100% !important;
    max-height: 100vh !important;
  }

  .LP_grid {
    padding-bottom: 2.75rem !important;
  }
}

.sticky-titles {
  position: sticky !important;
  top: 0px !important;
  z-index: 9999 !important;
  background: white !important;
  padding-top: 20px !important;
}

.sticky-titles h2 {
  margin-bottom: 5px !important;
}

.sticky-titles .column.text-left {
  margin-bottom: 0px !important;
  border-bottom: 0px solid white !important;
}

.LP_section {
  z-index: 9998 !important;
}

.LP_nav.nav-closed {
  z-index: 100000 !important;
}

.LP_search_modal,
.LP_modal {
  z-index: 99999999 !important;
}

.column-text a {
  font-weight: bold;
}
.markel-card a p {
  color: #4a4a4a !important;
}

.LP_nav {
  position: fixed !important;
}

.LP_hero {
  margin-top: 90px !important;
}

.searchcol {
  display: flex !important;
}

@media only screen and (min-width: 801px) {
  .LP_nav {
    position: fixed !important;
  }

  .LP_hero {
    margin-top: 110px !important;
  }

  .searchcol {
    display: flex !important;
  }
}

.row-map .container {
  max-width: 50% !important;
  width: 50% !important;
  min-width: 50% !important;
}

.address-grid {
  //display: flex;
  //justify-content: space-between;
  width: 100%;
  //align-items: stretch;
  margin-top: 35px !important;
}

.address-grid .markel-card .markel-card__body h4 {
  margin-bottom: 5px !important;
  padding-left: 80px;
}

.address-grid .markel-card .markel-card__body p {
  padding-left: 80px;
}

.address-grid .markel-card {
  max-width: calc(100%) !important;
  width: calc(100%) !important;
  min-width: calc(100%) !important;
  margin-bottom: 15px;
  height: 100% !important;
}

.map-wrapper {
  width: 100%;
  position: relative;
}

.row-map hr {
  border-color: #ddd !important;
}

.map-wrapper .map-marker {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #b71234;
  border-radius: 10px !important;
  left: calc(50% - 7.5px);
  top: calc(50% - 7.5px);
  cursor: pointer;
  transform: translateY(0rem);
}

.map-wrapper .map-marker:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}

.bdm_profile_section .section_header {
  border: 0px solid black;
}

.author img {
  display: block;
  max-width: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
}

.author-center {
  margin-bottom: 0px;
  text-align: center;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
}

.tab-key span {
  width: 15px;
  height: 15px;
  background-color: #b71234;
  border-radius: 10px !important;
  display: inline-block;
  margin-right: 10px;
}
.tab-key {
  display: flex;
  align-items: center;
}

.tab-key-contact span {
  width: 15px;
  height: 15px;
  background-color: #b71234;
  border-radius: 10px !important;
  display: inline-block;
  margin-right: 10px;
}

.button.contact {
  background-color: #b71234 !important;
  border-color: #b71234 !important;
}

.map-marker_contact {
  background-color: #b71234 !important;
}

.map-marker_contact span {
  color: #b71234 !important;
}

.address-grid {
  flex-wrap: wrap;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  display: flex;
  justify-content: space-between;
}

.profileimage {
  max-width: 60px;
  position: absolute;
}

.address-grid .markel-card {
  //width: calc(50% - 9px) !important;
  //max-width: calc(50% - 9px) !important;
  //min-width: calc(50% - 9px) !important;
}

.address-grid .markel-card .job {
  font-size: 19px !important;
  display: block;
  //padding-bottom: 10px;
  //border-bottom: 1px solid #eee;
  //margin-bottom: 10px !important;
}

.address-grid .markel-card:first-child {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}

.address-grid__contacts .markel-card__body h4 {
  color: #b71234 !important;
}

@media only screen and (max-width: 60rem) {
  .address-grid {
    flex-wrap: nowrap;
  }
}

.address-grid .gwZiig {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.map-panel .sc-ifAKCX.kZZOPs.sc-EHOje.aoTUt {
  width: auto !important;
  height: 39px !important;
  top: -59px !important;
  right: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.map-panel .sc-ifAKCX.kZZOPs.sc-EHOje.aoTUt .tabFilter {
  margin: 0px !important;
  height: 39px !important;
}

body {
  width: 100%;
}
.map-panel .sc-ifAKCX.kZZOPs.sc-bZQynM.dHQcjo {
  width: auto !important;
  height: 39px !important;
  top: -59px !important;
  right: 55px !important;
  left: auto !important;
  margin: 0px !important;
  padding: 0px !important;
}

.map-panel .sc-ifAKCX.kZZOPs.sc-bZQynM.dHQcjo .tabFilter {
  margin: 0px !important;
  height: 39px !important;
}

.map-arrow svg {
  width: 14px;
  height: 14px;
}

.map-arrow svg path {
  fill: #2c3038;
}

.author-center h2 {
  font-size: 30px !important;
}

.author-center p.lead {
  font-size: 18px !important;
}

.smallprint {
  font-size: 18px;
  margin-top: 25px;
  opacity: 1;
  border-top: 1px solid #ddd;
  padding-top: 30px;
}

.smallprint:hover {
  opacity: 1;
}

.smallprint h3 {
  margin-bottom: 10px !important;
  color: rgba(183, 18, 52, 1) !important;
}
.mapAbs {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.mapBlock {
  display: block;
}

.map-marker,
.map-tooltip {
  pointer-events: none !important;
}

.map-section {
  cursor: pointer;
}

.headerContact_top {
  font-size: 20px;
  line-height: 20px;
}

.headerContact_top span {
  font-weight: bold;
  color: #b71234;
}

.headerContact_bottom {
  font-size: 16px;
  line-height: 16px;
  margin-top: 5px;
  margin-bottom: -2px;
}

.headerContact_link {
  font-size: 15px;
  line-height: 16px;
  color: #4a4a4a !important;
  text-decoration: underline;
  display: none !important;
}

.headerContact {
  margin-right: 0px !important;
  //background: #f5f5f5;
  //padding: 10px;
  border-radius: 5px !important;
  text-align: right !important;
}

.header-search {
  width: 250px !important;
}

.header-search {
  width: 200px !important;
}

.home-ticks {
  min-height: 105px;
}

.contact_link,
.contact_tel {
  color: #b71234 !important;
  text-decoration: underline;
}

.contact_link:link,
.contact_tel:link,
.contact_link:hover,
.contact_tel:hover,
.contact_link:active,
.contact_tel:active,
.contact_link:visited,
.contact_tel:visited {
  color: #b71234 !important;
}

.popuptel {
  color: #b71234 !important;
  //text-decoration: underline;
  font-weight: bold;
}
.blacklead {
  color: #4a4a4a !important;
}
.redtext {
  color: #b71234 !important;
  font-weight: bold;
}
.contact_link:hover,
.contact_tel:hover {
  text-decoration: underline;
}

.popup_email,
.popup_tel {
  color: #b71234 !important;
  font-weight: bold !important;
}

.popup_email:link,
.popup_tel:link,
.popup_email:hover,
.popup_tel:hover,
.popup_email:active,
.popup_tel:active,
.popup_email:visited,
.popup_tel:visited {
  color: #b71234 !important;
}

.popup_email:hover,
.popup_tel:hover {
  text-decoration: underline;
}

.small_email,
.small_tel {
  color: #b71234 !important;
  font-weight: bold !important;
}

.small_email:link,
.small_tel:link,
.small_email:hover,
.small_tel:hover,
.small_email:active,
.small_tel:active,
.small_email:visited,
.small_tel:visited {
  color: #b71234 !important;
}

.small_email:hover,
.small_tel:hover {
  text-decoration: underline;
}

.column.text-left.author-center .lead.contactPhoneNumber {
  font-size: 22px !important;
  margin-bottom: 20px !important;
}

.column.text-left.author-center .lead.contactPhoneNumber span {
  //font-weight: bold !important;
  display: block;
  //margin-top: 10px;
}

.londonBlock {
  width: 15px;
  height: 15px;

  position: absolute;
  left: 75%;
  top: 80%;
  transform: scale(1.7);

  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
}

.londonBlock:hover {
  background-color: #a4848d;
}

.LP_hero__inner h1 {
  font-size: 50px !important;
  font-weight: bold;
  line-height: 1.2;
}

.LP_hero > .container {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.LP_hero__inner {
  margin-top: 0px !important;
}

.headerContact_top span {
  padding-top: 7px;
  display: inline-block;
}

.header-search {
  display: none !important;
}

.ontact_bottom {
  color: #b71234 !important;
  display: none !important;
}

.sit {
  font-size: 20px !important;
}

.headerContact {
  padding-top: 2px !important;
}

@media only screen and (max-width: 801px) {
  .LP_hero__inner h1 {
    font-size: 40px !important;
    font-weight: bold;
    line-height: 1;
  }
  .LP_hero > .container {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

.home-ticks .column .tick {
  font-size: 22px !important;
}

.largetel {
  font-size: 22px !important;
  font-weight: bold;
}

.markel-card.Quote {
  text-align: center !important;
  pointer-events: none;
  //padding: 3%;
  padding-left: 5%;
  border: 0px solid white !important;
  padding-right: 5%;
}

.markel-card.Quote .markel-card__body h4 {
  font-size: 30px !important;
}

.quoteAuthor {
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-bottom: 20px;
  padding-top: 0px;
}

.quoteAuthor .quoteAuthor__inner {
  width: 100px;
  height: 100px;
  background: transparent;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}
.quoteAuthor__inner img {
  padding: 0px !important;
  width: 50%;
  margin-left: 25%;
  margin-top: 25%;
}

.markel-card.Quote {
  text-align: center !important;
  pointer-events: none;
  padding-top: 0px;
  padding-bottom: 0px;
}

.section_header h3 {
  font-size: 30px;
}
.column-text h2 {
  font-size: 30px !important;
}

.LP_hero__inner p {
  margin-bottom: 25px !important;
}

.LP_hero__inner p:last-child {
  margin-bottom: 0px !important;
}
.headerContact_top {
  font-size: 22px;
}

.test-row .c-testimonial__quote {
  font-size: 20px !important;
}

.c-testimonial__star,
.test-row .c-testimonial__quote {
  margin-bottom: 0px !important;
}
.test-row .c-testimonial__star img {
  display: block;
}

.c-testimonials > .column {
  margin-bottom: 20px !important;
}

.c-testimonials .column.always {
  margin-bottom: 0px !important;
}

.ab_hero_logo {
  position: absolute;
  right: 40px;
  bottom: 57px;
  color: white;
}

.ab_hero_logo img {
  display: block;
  z-index: 99999;
  position: relative;
  max-width: 220px;
}

@media only screen and (max-width: 800px) {
  .ab_hero_logo {
    display: none !important;
  }
}

.contact_link-white:link,
.contact_tel-white:link,
.contact_link-white:hover,
.contact_tel-white:hover,
.contact_link-white:active,
.contact_tel-white:active,
.contact_link-white:visited,
.contact_tel-white:visited {
  color: white !important;
  text-decoration: underline;
}

.LP_bdm_modal {
  position: fixed !important;
  z-index: 9999999999999 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transition: opacity 0ms ease-in-out;
}

.column.text-left .lead.blacklead {
  font-size: 22px !important;
  margin-bottom: 0px !important;
}

.contact_tel > div {
  text-decoration: none !important;
  display: inline-block;
}

.LP_hero > .container.showForm {
  display: flex;
}

.ab_hero_logo {
  display: none !important;
}

.LP_hero > .container.showForm .LP_hero__inner {
  min-width: 50% !important;
  max-width: 50% !important;
  width: 50% !important;
}

#lp-pom-form-29 {
  position: relative !important;
  top: 0px !important;
  height: auto !important;
  left: 0px !important;
  width: 100% !important;
}

#add-form-here > div {
  max-width: 400px;
  background: #333;
  padding: 25px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
}

#add-form-here > div {
  max-width: 400px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
}
#add-form-here #lp-pom-form-29 {
  display: block !important;
}

#lp-pom-form-29 .lp-pom-form-field input[type="text"],
#lp-pom-form-29 .lp-pom-form-field input[type="email"],
#lp-pom-form-29 .lp-pom-form-field input[type="tel"],
#lp-pom-form-29 .lp-pom-form-field textarea,
#lp-pom-form-29 .lp-pom-form-field select {
  border-style: solid;
  border-width: 1px;
  border-color: #bbbbbb;
  background-color: white;
}

#lp-pom-form-29 .lp-pom-form-field .opt-label {
  color: white !important;
  font-size: 15px !important;
  line-height: 15px !important;
  font-weight: 400 !important;
}

#lp-pom-form-29 #ub-option-terms-item-0.option {
  height: auto !important;
}
#lp-pom-form-29 .option {
  display: flex;
  align-items: flex-start;
  height: 18px !important;
}

#lp-pom-form-29 button {
  left: 0px !important;
  width: 100% !important;
  top: 20px !important;

  height: 52px;

  background-color: #b71234 !important;
  color: #fff !important;
  font-size: 20px !important;
  border-color: #b71234 !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  border-radius: 0 !important;
  font-size: 20px !important;
  transition: all 0.1s ease-in-out !important;
  background-repeat: no-repeat !important;
  background-position: right 20px center !important;
  background-size: 30px !important;
  padding-right: 60px !important;
  margin-top: 20px !important;
  margin-bottom: d0px !important;
  background-image: url("https://unbouncemarkel.s3.eu-west-2.amazonaws.com/cd/buttonback.png") !important;
}

#add-form-here form {
  margin-bottom: 0px !important;
}

.formInner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.formInner input[type="checkbox"] {
  margin-right: 10px !important;
}

#add-form-here #formInstructions {
  display: none !important;
}

.formintro {
  font-size: 19px !important;
  background-color: hsla(0, 0%, 100%, 0.1);
  padding: 20px;
  margin-bottom: 20px !important;
}

.LP_hero__inner p.formintro {
  margin-bottom: 20px !important;
}

#add-form-here #formInstructions {
  display: none !important;
}

.LP_hero > .container.showForm {
  display: flex;
  align-items: center;
}

.bg-red.two-panel > .container > .row > .column:first-child {
  min-width: calc(37% - 15px);
  max-width: calc(37% - 15px);
  width: calc(37% - 15px);
}

.bg-red.two-panel > .container > .row > .column:last-child {
  min-width: calc(62% - 15px);
  max-width: calc(62% - 15px);
  width: calc(62% - 15px);
}

.bg-red.two-panel > .container > .row > .column:first-child {
  min-width: calc(37% - 15px);
  max-width: calc(37% - 15px);
  width: calc(37% - 15px);
}

.bg-red.two-panel > .container > .row > .column:last-child {
  min-width: calc(62% - 15px);
  max-width: calc(62% - 15px);
  width: calc(62% - 15px);
}

@media only screen and (max-width: 801px) {
  .bg-red.two-panel > .container > .row > .column:first-child {
    min-width: calc(100%);
    max-width: calc(100%);
    width: calc(100%);
  }

  .bg-red.two-panel > .container > .row > .column:last-child {
    min-width: calc(100%);
    max-width: calc(100%);
    width: calc(100%);
  }
}

.thisoneya {
  padding-right: 25px !important;
}

@media only screen and (max-width: 765px) {
  .hidemeonmobile {
    display: none;
  }

  .thisoneya {
    padding-right: 0px !important;
  }

  #add-form-here > div {
    max-width: 100%;
    margin-top: 30px;
  }

  .LP_hero > .container.showForm .LP_hero__inner {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    padding: 0px !important;
  }

  .LP_hero > .container.showForm {
    flex-direction: column;
  }
}

.ticklogo {
  text-align: center;
  padding: 20px;
}

.ticklogo img {
  max-width: 200px;
  max-height: 65px;
}

.logo-ticks .column {
  justify-content: center;
  max-width: 25%;
}

@media only screen and (max-width: 801px) {
  .logo-ticks .column {
    min-width: 50% !important;
    max-width: 50% !important;
  }

  .logo-ticks .row,
  .logo-ticks > .container {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}

.ticklogo img {
  display: block;
}

@media only screen and (max-width: 601px) {
  .ticklogo img {
    max-width: 130px;
    max-height: 65px;
  }
}

section.solo > .logo-ticks {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

@media only screen and (max-width: 400px) {
  .headerContact_top {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 801px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (max-width: 325px) {
  .headerContact_top {
    font-size: 18px !important;
  }
}

.feaure-heading {
  text-align: left !important;
  width: 100%;
  padding-top: 15px;
  margin-left: -10px !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.LP_section.bg-red.two-panel h2.feaure-heading {
  line-height: 1.2;
  font-size: 25px !important;
}

.feaure-sub {
  text-align: left !important;
  width: 100%;
  margin-left: -10px !important;
  margin-top: -12px !important;
  margin-bottom: 15px !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.LP_section.bg-red.two-panel h2 {
  font-size: 40px !important;
  font-weight: bold !important;
  line-height: 1.2;
}

svg.bigarrow {
  width: 170px;
  margin-bottom: 20px;
  margin-top: 10px;
}

svg.bigarrow g {
  stroke: white;
}

.largetel {
  max-width: 325px;
}
@media only screen and (max-width: 801px) {
  .largetel {
    max-width: none;
  }

  .LP_section.bg-red.two-panel h2 {
    font-size: 35px !important;
    margin-bottom: 20px !important;
  }
}

.markel-card__body.nopad {
  padding: 0px !important;
}

.hidemeonmobile {
  padding-top: 0px !important;
}

@media only screen and (max-width: 475px) {
  .hidemeonmobile {
    display: none !important;
    padding-top: 0px !important;
  }
}

#add-form-here {
  width: 100% !important;
  max-width: 400px !important;
}

@media only screen and (max-width: 801px) {
  #add-form-here {
    width: 100% !important;
    max-width: none !important;
  }
}

#container_company_name {
}

#container_contact_name {
}

#container_contact_email {
}

#container_contact_phone_number {
}

#container_contact_phone_number input {
  margin-bottom: 0px !important;
}

#container_are_you_a_limitedltd_company {
  display: none;
}

#container_is_the_company_a_going_concern {
  display: none;
}

#container_have_you_created_or_modified_any_products_or_processes_in_the_last_two_financial_years_ {
  display: none;
}
#container_terms {
  display: none;
}

#lp-pom-button-30 {
  display: none;
}

#move-form-button {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 60px !important;
}






.formPage__1 #container_company_name {
}

.formPage__1 #container_contact_name {
}

.formPage__1 #container_contact_email {
}

.formPage__1 #container_contact_phone_number {
}

.formPage__1 #container_contact_phone_number input {
  margin-bottom: 0px !important;
}

.formPage__1 #container_are_you_a_limitedltd_company {
  display: none;
}

.formPage__1 #container_is_the_company_a_going_concern {
  display: none;
}

.formPage__1 #container_have_you_created_or_modified_any_products_or_processes_in_the_last_two_financial_years_ {
  display: none;
}
.formPage__1 #container_terms {
  display: none;
}

.formPage__1 #lp-pom-button-30 {
  display: none;
}

.formPage__1 #move-form-button {


}





.formPage__2 #container_company_name {
  display: none;
}

.formPage__2 #container_contact_name {
  display: none;
}

.formPage__2 #container_contact_email {
  display: none;
}

.formPage__2 #container_contact_phone_number {
  display: none;
}




.formPage__3 #container_company_name {
  display: none;
}

.formPage__3 #container_contact_name {
  display: none;
}

.formPage__3 #container_contact_email {
  display: none;
}

.formPage__3 #container_contact_phone_number {
  display: none;
}


.formPage__2 #container_contact_phone_number input {
  margin-bottom: 0px !important;
}

.formPage__2 #container_are_you_a_limitedltd_company {
  display: block;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid rgba(255,255,255,0.25);

}

.formPage__2 #container_is_the_company_a_going_concern {
  display: block;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid rgba(255,255,255,0.25);

}



.formPage__2 #container_have_you_created_or_modified_any_products_or_processes_in_the_last_two_financial_years_ {
  display: block;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid rgba(255,255,255,0.1);

}


.formPage__1 #container_has_the_company_spent_in_excess {
  display: none;
  

}




.formPage__3 #container_has_the_company_spent_in_excess{
 display:none;
}


.formPage__3 #container_are_you_a_limitedltd_company label{
 display:none;
}


.formPage__3 #container_is_the_company_a_going_concern label{
 display:none;
}










.formPage__2 #container_has_the_company_spent_in_excess {
  display: block;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid rgba(255,255,255,0.1);

}
.formPage__2 #container_has_the_company_spent_in_excess{
  margin-bottom: 10px !important;
}



.formPage__2 #container_have_you_created_or_modified_any_products_or_processes_in_the_last_two_financial_years_ label{
  margin-bottom: 10px !important;
}

.formPage__2 #container_are_you_a_limitedltd_company label{
  margin-bottom: 10px !important;
}


.formPage__2 #container_is_the_company_a_going_concern label{
  margin-bottom: 10px !important;
}




.formPage__2 #container_terms {
  display: none;
}


.formPage__3 #container_terms {
  display: block;
}
.formPage__2 #lp-pom-button-30 {
  display: none;
}

.formPage__3 #lp-pom-button-30 {
  display: block;
}

.formPage__3 #move-form-button {

display:none;

}

#move-back-link {
display: none;
}

.formPage__2 #move-back-link {
  display: inline-block;
}


.formPage__3 #move-back-link {
  display: inline-block;
}



#lp-pom-form-29 .optionsList {
  margin-bottom: 0px !important;
  display: flex;
  margin-top: 10px;
}


#add-form-here > div.back_form{
  width:20px;
  height: 20px;
  position: absolute;
  top:0px;
  right:0px;
  z-index: 1;
}

#add-form-here > div.back_form svg path{


 fill:#fff;

}

#add-form-here > div.back_form svg{
  position: absolute;
top:0px;
right:0px;
  width:100%;
  height: 100%;
  display: block;
 }


 .progress_bar{
   position: absolute;
   left:0px;
   top:0px;
   width: 100%;
   height: 5px;
   background-color: #b71234;
 }

 #move-back-link{
   cursor: pointer;
   margin-top:5px;
 }