.LP_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.LP_modal__inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  //pointer-events: none !important;
}

.LP_modal .markel-card {
  background-color: white;
  padding: 10px;
  border-radius: px;
  overflow: hidden;
  border: 0px solid white;
  width: 100%;
  max-width: 900px;
  height: auto !important;
  z-index: 999999;
  //pointer-events: none !important;
  position: relative;
  overflow: visible;
}

.LP_modal .markel-card__header:after {
  opacity: 0 !important;
  pointer-events: none !important;
}

.LP_modal__close {
  position: absolute;
  top: -35px;
  right: 0px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.LP_modal__close:hover {
  text-decoration: underline;
}

.LP_search_modal {
}

.LP_search_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.LP_search_modal__inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  //pointer-events: none !important;
}

.LP_search_modal .markel-results {
  background-color: white;
  padding: 10px;
  border-radius: px;
  overflow: hidden;
  border: 0px solid white;
  width: 100%;
  max-width: 900px;
  height: auto !important;
  z-index: 999999;
  //pointer-events: none !important;
  position: relative;
  overflow: visible;
}

.LP_search_modal .markel-card__header:after {
  opacity: 0 !important;
  pointer-events: none !important;
}

.LP_search_modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
  z-index: 9999999;
}

.LP_search_modal__close:hover {
  text-decoration: underline;
}

.LP_search_modal {
}

.LP_bdm_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  

  background: hsla(0, 0%, 0%, 0.6);

  z-index: 19999990;
}

.bdm_profile_section {
  background-color: #fff !important;
}
.LP_bdm_modal__inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  //pointer-events: none !important;
}

.bdm_profile_section {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.LP_bdm_modal .markel-results {
  background-color: white;
  padding: 10px;
  border-radius: px;
  overflow: hidden;
  border: 0px solid white;
  width: 100%;
  max-width: 900px;
  height: auto !important;
  z-index: 999999;
  //pointer-events: none !important;
  position: relative;
  overflow: visible;
}

.LP_bdm_modal .markel-card__header:after {
  opacity: 0 !important;
  pointer-events: none !important;
}

.LP_bdm_modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
  z-index: 9999999;
}

.LP_bdm_modal__close:hover {
  text-decoration: underline;
}

.LP_bdm_modal .column.text-left {
  border: 0px solid white !important;
}

.map-tooltip {
  position: absolute;
  right: calc(100% + 7px) !important;
  //width: auto !important;
  top: -5px !important;
  background-color: white;
  border-radius: 0px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  font-size: 18px;
  font-weight: normal;
  padding: 12px;
  line-height: 1.1;
  pointer-events: none;
  width: 140px !important;
  color: #2c3038;

  transform: translateY(-0rem);
  transition-property: transform;
  transition-duration: 0.5s;
}

.map-tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  top: 8px;
  right: -20px;
}

.map-tooltip span {
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
  line-height: 1.1;
  pointer-events: none;
  width: 80px;
  color: #b71234;
  display: block;
  padding-bottom: 3px;
}

.map-marker:hover .map-tooltip {
  opacity: 1;
  pointer-events: all;
  transform: translateY(-0.5rem);
}

.map-marker:hover {
  z-index: 99999 !important;
}

.map-marker.is-active .map-tooltip {
  opacity: 1;
  pointer-events: all;
  transform: translateY(-0.5rem);
}

.map-marker.is-active {
  z-index: 99999 !important;
}
