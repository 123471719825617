.c-testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.c-testimonial__star img {
  max-width: 100px;
}

.c-testimonial__star {
  margin-bottom: 10px;
}

.c-testimonial__quote {
  font-size: 22px;
  margin-bottom: 10px;
}

.bg-red .c-testimonial__quote {
  font-size: 22px;
  font-weight: bold !important;
  margin-bottom: 10px;
}

.c-testimonial__author {
  font-size: 18px;
  color: #014e44;
}

.c-testimonial__author span {
  font-weight: bold;
}

@media only screen and (max-width: 801px) {
  .c-testimonials > .column {
    margin-bottom: 20px;
  }

  .c-testimonials > .column:last-child {
    display: block;
    margin-bottom: 0px;
  }
}

.c-testimonial__star img {
  width: 60px;
  height: 60px;
}

.test-row {
  flex-direction: row !important;
  text-align: left !important;
}

.test-row img {
  margin-right: 20px;
}

.test-row .c-testimonial__quote {
  font-weight: normal;
  font-size: 22px;
}

.test-row .c-testimonial__star img {
  width: 50px;
  height: 50px;
}



.LP_nav_acturis {
  position: fixed !important;
  top:0px !important
}
