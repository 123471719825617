/* Card */

.markel-card {
  border: 1px solid #ddd;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 100%;
}
.markel-card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
  transform: translateY(-0.5rem);
}

.no-hover:hover {
  transform: translateY(0em) !important;
  cursor: pointer !important;
}

.markel-card__body {
  padding: 20px !important;
}

.markel-card__body p {
  margin-bottom: 0px !important;
  font-size: 18px !important;
}
.markel-card__body h4 {
  font-size: 24px !important;
  font-weight: normal !important;
  color: rgba(183, 18, 52, 1) !important;
  margin-bottom: 10px !important;
  line-height: 1.25;
}
.markel-card__header {
  position: relative;
}

.markel-card__header img {
  width: 100%;
  display: block;
}

.markel-card__header:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(35deg, hsla(214, 23%, 12%, 0.75) 0%, hsla(214, 23%, 12%, 0) 50%, hsla(214, 23%, 12%, 0) 100%);
  background: -moz-linear-gradient(35deg, hsla(214, 23%, 12%, 0.75) 0%, hsla(214, 23%, 12%, 0) 50%, hsla(214, 23%, 12%, 0) 100%);
  background: -webkit-linear-gradient(35deg, hsla(214, 23%, 12%, 0.75) 0%, hsla(214, 23%, 12%, 0) 50%, hsla(214, 23%, 12%, 0) 100%);
}

.markel-card__header img {
  display: block;
}

.markel-card__badge {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 16px !important;
  z-index: 1;
  color: #fff;
  background-color: rgba(183, 18, 52, 1);
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  padding: 12px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;

  display: flex;
  align-items: center;
}

.markel-card__badge svg {
  margin-right: 10px;
  max-width: 20px;
  max-height: 20px;
}
