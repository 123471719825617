/* Nav */

.LP_nav {
  background: #ffffff;
  border-bottom: 0.1rem solid #d1d1d1;
  display: block;
  left: 0;
  max-width: 100%;
  position: sticky;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LP_nav .column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.LP_nav .column:last-child {
  text-align: right;
  justify-content: flex-end !important;
}

.LP_nav .row {
  flex-direction: row !important;
}

.LP_nav img {
  display: inline-block;
  max-height: 95px;
}

.LP_nav img.LP_logo {
  max-height: 100px;
}
.LP_nav .column:last-child img {
  max-height: 85px;
  //display: block;
  padding-bottom: 0px !important;
  //transform: scale(0.9);
}

@media only screen and (max-width: 801px) {
  .two-panel .column:last-child {
    padding-bottom: 0px !important;
  }

  .two-panel-small .column-image {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
  }
}

@media only screen and (min-width: 1200px) {
  .two-panel-small .column-image {
    min-width: 300px;
    width: 300px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 801px) {
  .LP_section,
  footer {
    padding-top: 4.75rem;
  }

  .LP_nav img {
    display: inline-block;
    max-height: 75px;
  }
  .LP_nav .column:last-child img {
    max-height: 65px;
    //display: block;
    padding-bottom: 0px !important;
  }

  .LP_nav {
    height: 90px;
  }
}

.Caunce_logo {
  max-height: 135px !important;
  position: absolute;
  top: -12px;
}
