/* Hero */

.LP_hero {
  min-height: calc(50vh);
  //height: calc(100vh - 110px);
  //max-height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: #f0f0f0;
  background-position: center center;
  color: white;
}

.formback,
.the-form {
  position: relative;
}

.the-form {
  z-index: 10;
}

.LP_hero:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
}

.formback:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0.5) 100%);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0.5) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0.5) 100%);
}

.LP_hero > .container {
  padding-top: 140px;
  padding-bottom: 140px;
}
.LP_hero__inner {
  max-width: 750px;
  min-width: 750px;
  width: 50%;
  margin-top: -10px;
  position: relative;
  z-index: 1;
}

.LP_section.bg-red.two-panel h2 {
  font-size: 30px;
}

h2 {
  font-size: 30px !important;
}

.LP_hero__inner h1 {
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
}

.LP_hero__inner p {
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 1.3;
}

/* Mobile */

@media only screen and (max-width: 801px) {
  .LP_hero > .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .LP_hero__inner {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }

  .LP_hero__inner h1 {
    font-size: 45px;
  }

  .LP_hero__inner p {
    font-size: 22px;
  }

  .LP_hero {
    //min-height: calc(100vh - 90px);
    //height: calc(100vh - 90px);
    //max-height: calc(100vh - 90px);
  }
}
