/* buttons */

.button {
  white-space: normal !important;
  text-align: left !important;
  line-height: 1.2 !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-left: 20px !important;
}

.button:hover,
button:hover {
  transform: scale(1.033);
}

.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: #b71234;
  color: white;
  font-size: 20px;
  border-color: #b71234;
  height: auto;
  text-transform: none;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  border-radius: 0px !important;
  font-size: 20px !important;
  transition: all 0.1s ease-in-out;

  background-image: url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/cd/buttonback.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 30px;

  padding-right: 60px;
}

.button:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  background-color: #b71234;
  color: white;
  border-color: #b71234;
}

.button:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  background-color: #b71234;
  color: white;
  border-color: #b71234;
}

.button:active,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
  background-color: #b71234;
  color: white;
  border-color: #b71234;
}

.bg-blue .button,
.bg-blue button,
.bg-blue input[type="button"],
.bg-blue input[type="reset"],
.bg-blue input[type="submit"] {
  background: #ffc629 !important;
  border-color: #ffc629 !important;
  color: #0f143c !important;
  font-weight: normal !important;
  border-radius: 5px !important;
  display: inline-flex;
  align-items: center;
  padding-right: 3rem;
}

.bg-blue .button span,
.bg-blue button span,
.bg-blue input[type="button"] span,
.bg-blue input[type="reset"] span,
.bg-blue input[type="submit"] span {
  margin-left: 15px;
}

.bg-blue .button:hover,
.bg-blue button:hover,
.bg-blue input[type="button"]:hover,
.bg-blue input[type="reset"]:hover,
.bg-blue input[type="submit"]:hover {
  background: #ffc629 !important;
  border-color: #ffc629 !important;

  color: #0f143c !important;
  font-weight: normal !important;
  border-radius: 5px !important;
}

.button {
  margin-bottom: 0px !important;
}
