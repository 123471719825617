.home-ticks {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.home-ticks-container-offset {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-ticks-container-offset > .container {
  height: 100%;
  padding: 30px;
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-ticks .row,
.home-ticks > .container {
  height: 100%;
  display: flex;
  align-items: center;
}

.home-ticks .column {
  height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.home-ticks .column .tick {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;

  font-size: 20px;
  color: #fff;

  padding-right: 60px;

  background-image: url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/sp/TickWhite.svg);
  background-repeat: no-repeat !important;
  background-position: left center !important;
  padding-left: 60px !important;
  line-height: 1.25;
  background-size: 32px;
  font-size: 22px !important;
}

.home-ticks .column .tick span {
  display: inline-block;
  //max-width: 175px;
}
@media only screen and (max-width: 801px) {
  .home-ticks .column .tick {
    font-size: 22px !important;
  }

  .home-ticks {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .home-ticks .column .tick {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .home-ticks-container-offset {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .home-ticks-container-offset > .container {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .home-ticks .row,
  .home-ticks > .container {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: none;
    flex-direction: column;
  }

  .home-ticks .column {
    min-width: 100%;
  }

  .LP_hero__feefo {
    bottom: 4.75rem;
  }

  .home-ticks .column .tick span {
    display: inline-block;
    max-width: 100%;
  }
}

.feature-ticks {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.feature-ticks-container-offset {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-ticks-container-offset > .container {
  height: 100%;
  padding: 30px;
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-ticks .row,
.feature-ticks > .container {
  height: 100%;
  display: flex;
  align-items: center;
}

.feature-ticks .column {
  height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px !important;
}

.feature-ticks .column .tick {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;

  font-size: 20px;
  color: #fff;

  padding-right: 60px;

  background-image: url(https://unbouncemarkel.s3.eu-west-2.amazonaws.com/sp/TickWhite.svg);
  background-repeat: no-repeat !important;
  background-position: left center !important;
  padding-left: 60px !important;
  line-height: 1.25;
  background-size: 32px;
  font-size: 20px !important;
}

.feature-ticks .column .tick span {
  display: inline-block;
  //max-width: 175px;
}

.feature-ticks .column .tick {
  font-size: 20px !important;
}

.feature-ticks {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}
.feature-ticks .column .tick {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.feature-ticks-container-offset {
  padding-top: 0px;
  padding-bottom: 0px;
}

.feature-ticks-container-offset > .container {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.feature-ticks .row,
.feature-ticks > .container {
  height: 100%;
  display: flex;
  align-items: center;
  min-height: none;
  flex-direction: column;
}

.feature-ticks .column {
  min-width: 100%;
}

.feature-ticks .column .tick span {
  display: inline-block;
  max-width: 100%;
}
