.bg-light {
  background: #f0f0f0;
}

.bg-white {
  background: #ffffff;
}

.bg-red {
  background: radial-gradient(circle, #b71234 0, #6f1a2b 100%);
  color: white;
}

h2 {
  color: #2c3038;
}

.bg-blue {
  background: hsla(213, 100%, 43%, 1);
  background: linear-gradient(270deg, hsla(213, 100%, 43%, 1) 0%, hsla(213, 72%, 49%, 1) 100%);
  background: -moz-linear-gradient(270deg, hsla(213, 100%, 43%, 1) 0%, hsla(213, 72%, 49%, 1) 100%);
  background: -webkit-linear-gradient(270deg, hsla(213, 100%, 43%, 1) 0%, hsla(213, 72%, 49%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0064DC", endColorstr="#2373D5", GradientType=1 );
  color: white;
}

.bg-blue h2,
.bg-red h2,
.bg-dark h2 {
  color: #fff;
}

.bg-green {
  background: #00664e !important;
  color: white;
}

.bg-dark {
  background: #333 !important;
  color: white;
}

.bg-dark-grad {
  background: radial-gradient(circle, #515355 0, #1f1f1f 100%) !important;
}
