.LP_grid {
  padding-bottom: 5rem !important;
}

.LP_grid2 {
  padding-bottom: 4rem !important;
}

.two-panel img {
  display: block;
}
.column-text p:last-child {
  margin-bottom: 0px !important;
}

.two-panel {
  text-align: left;
}

.two-panel .row {
  justify-content: space-between;
  align-items: center;
}

.two-panel .column {
  min-width: calc(50% - 15px);
  max-width: calc(50% - 15px);
  width: calc(50% - 15px);
}

.column-text {
  //padding-right: 3rem !important;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: 5px !important;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.one-panel .container {
  //padding-right: 20%;
}

@media only screen and (max-width: 801px) {
  .one-panel .container {
    //padding-right: 3rem;
  }

  .column-image,
  .column-video {
    //margin-top: 40px;
    //padding-left: 10px !important;
    //padding-right: 10px !important;
  }
  .two-panel .row {
    flex-direction: column !important;
  }

  .two-panel .column {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }

  .two-panel .column:last-child {
    padding-top: 40px;
    padding-right: 0px;
  }
}

.two-panel--reverse .column-video {
  //padding-right: 3rem !important;
}

.one-panel .button,
.two-panel .button {
  margin-top: 10px !important;
}

.one-panel,
.two-panel {
  position: relative !important;
  overflow: hidden;
}

.column.text-left .lead {
  font-size: 20px !important;
}

.section_header .column {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.flex-center {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  text-align: center !important;
}

.markel-card__body .button {
  margin-top: -5px !important;
}

.column-product-single .markel-card {
  margin-bottom: 0px !important;
}

.two-panel-small .column-image {
  min-width: 300px;
  width: 300px;
  max-width: 300px;
}

.two-panel-small .column-text {
  min-width: 1px;
  width: 100%;
  max-width: 100%;
}

.one-panel.LP_section {
  padding-bottom: 5.75rem;
}

.column-text > ul > li {
  list-style: decimal !important;
}

.LP_nav {
  position: fixed !important;
  top: 0px !important;
  z-index: 9999999999999999 !important;
}

.Markel-LP {
  //padding-top: 180px !important;
}
@media only screen and (max-width: 475px) {
  .Markel-LP {
    //padding-top: 90px !important;
  }
}

.c-testimonials {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
}

.c-testimonials .column {
  width: 33.3% !important;
  max-width: 33.3% !important;
  min-width: 33.3% !important;
  margin-bottom: 20px !important;
}

.fourplease .c-testimonials .column {
  width: 25% !important;
  max-width: 25% !important;
  min-width: 25% !important;
}

@media only screen and (max-width: 801px) {
  .fourplease .c-testimonials .column {
    width: 50% !important;
    max-width: 50% !important;
    min-width: 50% !important;
  }

  .c-testimonials .column {
    width: 50% !important;
    max-width: 50% !important;
    min-width: 50% !important;
  }
  .stayhundred .c-testimonials .column {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .stayhundred button {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}

.fourplease .c-testimonials .column.always {
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}

.c-testimonials .column.always {
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}

@media only screen and (max-width: 580px) {
  .c-testimonials .column {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .test-row .c-testimonial__star img {
    width: 40px !important;
    height: 40px !important;
  }

  .c-testimonials > .column {
    margin-bottom: 10px !important;
  }

  .greyboxes .c-testimonials .column .test-row {
    //flex-direction: column !important;
    //text-align: left !important;
    //align-items: flex-start;
  }

  .c-testimonial__star {
    margin-bottom: 2px;
  }
}

.LP_hero > .container,
.LP_nav > .container {
  margin: 0px !important;
}
