.M-Footer {
  display: flex;
  flex-direction: column;
}

.M-Footer-row {
  display: flex;
  flex-direction: row;
}

.M-Footer-col {
  width: 100%;
}
.M-Footer-col-fixed {
  min-width: 200px !important;
  max-width: 200px !important;
  width: 200px !important;
}

.footer__contentList {
  color: white;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.footer__contentList li {
  color: white;
  margin-right: 15px;
}

.footer__contentList li a {
  color: white;
}

.footer__contentList li a:hover {
  text-decoration: underline;
}

.footer-logo-col {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media only screen and (max-width: 801px) {


  .row-reverse .column-image.thisonemobile{

    margin-top:40px;
    padding:0px !important
  }
  .M-Footer-row {
    display: flex;
    flex-direction: column-reverse;
  }

  .footer-logo-col {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer__contentList {
    margin-top: 20px;
  }
}


  .LP_menu_bar p{

margin-bottom: 0px !important;
}

.LP_menu_bar {
  border-top:1px solid #d1d1d1;
  padding-top:40px;
  padding-bottom:40px;
  top: 109px !important;
  position: sticky !important;
  z-index: 999 !important;
}


.logo-desktop{
  display: block !important;
}


.logo-mobile{
  display: none !important;
}


@media only screen and (max-width: 501px) {


  .logo-desktop{
    display: none !important;
  }


  .logo-mobile{
    display: block !important;
  }


}